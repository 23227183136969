import React, { useCallback, useContext, useEffect, useState } from 'react';
import congesService from '@data/congesService';
import { useTranslation } from 'react-i18next';

import NotificationContext from '@context/NotificationContext';

import {
  faFileInvoice,
  faPen,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';

import ToastCustom from '@components/UI/ToastCustom';
import SwalCustom from '@helpers/SwalCustom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DocumentationModal from '@components/Setting/Documentation/DocumentationModal';
import Spinner from '@components/UI/Spinner';
import { ButtonFirstAction } from '@components/UI/Buttons';

import Lottie from 'lottie-react';
import empty from '@assets/lottie/empty';
import { capitalizeFirstLetter, getExtension } from '@utility/Utils';

const style = {
  height: 300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const initalPreview = {
  name: '',
  ids: [],
  id: '',
  documentation: [],
};

function Documentations() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [preview, setPreview] = useState(initalPreview);
  const [sites, setSites] = useState([]);
  const { t } = useTranslation();

  const fetchDocuments = async () => {
    try {
      const response = await congesService.get(
        '/v1/documentation?sameClient=1'
      );
      setDocuments(response.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchSites = async () => {
    try {
      const response = await congesService.get(
        `/v1/sites?withSiteDatabase=1&withDocumentations=1`
      );
      setSites(response.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const initData = async () => {
    try {
      await fetchDocuments();
      await fetchSites();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = (e, doc) => {
    e.stopPropagation();
    congesService
      .get(`/v1/documentation/${doc.id}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', doc.real_name);
        document.body.appendChild(link);
        link.click();
        ToastCustom.validated(t('downloadSuccess'));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleToggleModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handlePreviewDocument = (e, document) => {
    e.stopPropagation();
    congesService
      .get(`/v1/documentation/${document.id}`, {
        responseType: 'blob',
      })
      .then((response) => {
        setPreview((prevState) => ({
          ...prevState,
          name: document.name,
          ids: sites.filter((site) => {
            return document.sites.includes(site.id);
          }),
          id: document.id,
          documentation: [
            {
              name: document.name,
              type: capitalizeFirstLetter(getExtension(document.real_name)),
            },
          ],
        }));
      })
      .then(() => handleToggleModal())
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDelete = (e, document) => {
    e.stopPropagation();
    SwalCustom.delete(
      async () => {
        try {
          await congesService.delete(`/v1/documentation/${document.id}`);
          ToastCustom.validated();
          fetchDocuments();
        } catch (err) {
          console.error(err);
        }
      },
      {
        title: t('areYouSureContinue'),
        text: t('deleteAreForever'),
      }
    );
  };

  useEffect(() => {
    initData().then(() => setIsLoading(false));
  }, []);

  return isLoading ? (
    <Spinner />
  ) : (
    <div className='flex flex-col min-h-[50vh]'>
      <div className='flex justify-end my-2'>
        <ButtonFirstAction
          onClick={() => {
            setPreview(initalPreview);
            handleToggleModal();
          }}
        >
          {t('addDocument')}
        </ButtonFirstAction>
      </div>
      <ul className='flex flex-col gap-y-5 my-2 '>
        {documents?.length > 0 ? (
          documents.map((document, index) => {
            return (
              <li
                key={index}
                className={
                  'shadow-leave bg-white flex justify-between flex-1 items-center w-full h-8 px-6 py-4 rounded-[15px] cursor-pointer'
                }
                onClick={(e) => handleDownload(e, document)}
              >
                <div className='text-lg'>
                  <FontAwesomeIcon icon={faFileInvoice} size='xl' />
                  <span className='mx-4'>{document.name}</span>
                </div>

                <div>
                  <button
                    onClick={(e) => handlePreviewDocument(e, document)}
                    className='bg-primary1 text-white rounded-md px-2 py-1 hover:bg-lightgrey-900 active:bg-lightgrey-500 transition-all duration-200'
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </button>
                  <button
                    onClick={(e) => handleDelete(e, document)}
                    className='bg-primary1 text-white rounded-md px-2 py-1 hover:bg-lightgrey-900 active:bg-lightgrey-500 transition-all duration-200 ml-2'
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </li>
            );
          })
        ) : (
          <section className='flex flex-col items-center justify-center relative min-h-[50vh]'>
            <Lottie animationData={empty} loop={true} style={style} />
            <h4>{t('noDocuments')}</h4>
          </section>
        )}
      </ul>
      <DocumentationModal
        sites={sites}
        preview={preview}
        setPreview={setPreview}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={t('addDocument')}
        fetchDocuments={fetchDocuments}
      />
    </div>
  );
}

export default Documentations;
